<!--
/*
 * @Author:张天鹏
 * @Date: 2020-03-07 15:03:31
 * @LastEditors: 刘旭
 * @LastEditTime: 2020-07-06 16:18:48
 */
 -->
<template>
  <div class="mainbody">
    <div>
      <div class="selectInsurance">
        <div class="longRisk" :class="isActive==='R'?'isActive':''" @click="selectRisk('rsx')">人身险</div>
        <div class="shortRisk" :class="isActive==='C'?'isActive':''" @click="selectRisk('ccx')">财产险</div>
      </div>
      <div v-if="isActive==='R'">
        <md-tab-bar
          v-model="current"
          class="tabBar"
          :items="items"
          @change="onTabChange"
          style="width:100%"
        />
        <div class="list" :style="{'height': (reportList.length ? '85vh' :'100vh')}">
          <md-scroll-view
            ref="scrollView"
            auto-reflow
            :scrolling-x="false"
            @end-reached="loadMore"
            v-show="!isNull"
          >
            <div class="product" v-for="(item,index) of reportList" :key="index">
              <div>
                <div class="producttop">
                  <div class="producttopl">
                    <p class="omit">{{item.prodname}}</p>
                  </div>
                  <div class="producttopr" v-if="item.ddstatus=='0'">
                    <p>
                      <span>待支付</span>
                    </p>
                  </div>
                  <div class="producttopr" v-if="item.ddstatus=='1'&item.polist == 2">
                    <p>
                      <span class="succe">已完成</span>
                    </p>
                  </div>
                  <div
                    class="producttopr"
                    v-if="item.ddstatus==1&item.polist == 8||item.ddstatus==1&item.polist == 9"
                  >
                    <p>
                      <span class="succe">已退保</span>
                    </p>
                  </div>
                </div>
                <div class="orderContent">
                  <div class="productcen">
                    <div class="productcon">
                      <div class="productconl">
                        <p>订单号：{{item.orderno}}</p>
                      </div>
                    </div>
                    <div class="productcon">
                      <div class="productconl">
                        <p>投保人：{{item.apidname}}</p>
                      </div>
                    </div>
                    <div class="productcon">
                      <div class="productconl insuredName">
                        <p>被保人：{{item.pidname}}</p>
                      </div>
                    </div>
                    <!-- <div class="productcon">
                      <div class="productconl">
                        <p>出单人：{{user.empname}}</p>
                      </div>
                    </div>-->
                    <div class="productcon">
                      <div class="productconl">
                        <p>投保时间：{{item.appdate}}</p>
                      </div>
                    </div>
                    <div class="productcon" v-if="item.ddstatus==1&item.polist == 8||item.ddstatus==1&item.polist == 9">
                      <div class="productconl">
                        <p>退保时间：{{item.tbdate}}</p>
                      </div>
                    </div>
                  </div>
                  <div class="lookOrder" @click="skip(item)" v-if="item.ddstatus!='0'">查看订单</div>
                </div>
              </div>
              <div class="productbom">
                <div class="totalMount">
                  <div class="productboml">
                    <span class="wfont">
                      总保费:
                      <span style="font-size:0.27rem;color:#242424">￥</span>
                      <span class="hfont">{{item.mount}}</span>
                    </span>
                  </div>
                </div>
                <div class="showBtn gotoPay" v-if="item.paymenturl&&item.ddstatus==0">
                <!-- <div class="productbomr">
                  <md-button type="default" size="small" round @click="deldata(item.orderno)">删除订单</md-button>
                </div> -->
                <div class="payMony"  @click="onCopyUrl(item.paymenturl)">去支付</div>
                </div>
              </div>
            </div>
            <md-scroll-view-more slot="more" :is-finished="listRsFinished" />
          </md-scroll-view>
          <div class="nodata" v-show="isNull">
            <img class="kongimg" src="@/assets/abd/image/null.png" alt />
            <p class="asdasdqwe">目前还没有内容哦~</p>
          </div>
        </div>
      </div>
      <div v-if="isActive==='C'">
        <md-tab-bar
          v-model="current"
          class="tabBar"
          :items="items"
          @change="onTabChange"
          style="width:100%"
        />
        <div class="list" :style="{'height': (reportListCaixian.length ? '85vh' :'100vh')}">
          <md-scroll-view
            v-show="!isNull"
            ref="scrollView"
            auto-reflow
            :scrolling-x="false"
            @end-reached="loadMorechange"
          >
            <div class="product" v-for="(item,index) of reportListCaixian" :key="index">
              <div>
                <div class="producttop">
                  <div class="producttopl">
                    <p class="omit">{{item.prodName}}</p>
                  </div>
                  <div class="producttopr" v-if="item.orderStatus=='1'">
                    <p>
                      <span>暂存订单</span>
                    </p>
                  </div>
                  <div class="producttopr" v-if="item.orderStatus=='2'">
                    <p>
                      <span>核保中</span>
                    </p>
                  </div>
                  <div class="producttopr" v-if="item.orderStatus=='3'">
                    <p>
                      <span>核保失败</span>
                    </p>
                  </div>
                  <div class="producttopr" v-if="item.orderStatus=='4'">
                    <p>
                      <span>待支付</span>
                    </p>
                  </div>
                  <div class="producttopr" v-if="item.orderStatus=='5'||item.orderStatus=='6'">
                    <p>
                      <span>已完成</span>
                    </p>
                  </div>
                  <div class="producttopr" v-if="item.orderStatus=='7'">
                    <p>
                      <span>退保</span>
                    </p>
                  </div>
                </div>
                <div class="orderContent">
                  <div class="productcen">
                    <div class="productcon">
                      <div class="productconl">
                        <p>订单号：{{item.channelOrderCode}}</p>
                      </div>
                    </div>
                    <div class="productcon">
                      <div class="productconl">
                        <p>投保人：{{item.paidName}}</p>
                      </div>
                    </div>
                    <div class="productcon">
                      <div class="productconl insuredName">
                        <p>被保人：{{item.ipaidName}}</p>
                      </div>
                    </div>
                    <!-- <div class="productcon">
                      <div class="productconl">
                        <p>出单人：{{item.empname}}</p>
                      </div>
                    </div>-->
                    <div class="productcon">
                      <div class="productconl">
                        <p>投保时间：{{item.geneTime}}</p>
                      </div>
                    </div>
                    <!-- <div class="productcon" v-if="item.orderStatus=='7'">
                      <div class="productconl">
                        <p>退保时间时间：{{item.tbdate}}</p>
                      </div>
                    </div> -->
                  </div>
                  <div class="lookOrder" @click="skip(item)" v-if="item.orderStatus!='1'">查看订单</div>
                  <div
                    class="lookOrder"
                    @click="channelCoopCall(item.channelOrderCode,item.correctNo,item.baseid,'7')"
                    v-if="item.orderStatus==='1'"
                  >修改订单</div>
                </div>
              </div>
              <div class="productbom">
                <div class="productbomFrist">
                  <div class="totalMount">
                    <div class="productboml">
                      <span class="wfont">
                        总保费:
                        <span class="mondyUnit">￥</span>
                        <span class="hfont">{{item.premium}}</span>
                      </span>
                    </div>
                  </div>
                  <div class="showBtn">
                    <div
                      class="productbomr"
                      v-if="item.orderStatus==='1'||item.orderStatus==='2'||item.orderStatus==='3'||item.orderStatus==='4'"
                    >
                      <md-button
                        class="delet"
                        size="small"
                        round
                        @click="deleteOrder(item.channelOrderCode,item.correctNo,item.baseid,'6')"
                      >删除订单</md-button>
                    </div>
                    <div
                      class="payMony"
                      v-if="item.orderStatus==='4'"
                      @click="channelCoopCall(item.channelOrderCode,item.correctNo,item.baseid,'2')"
                    >立即支付</div>
                  </div>
                </div>
                <div class="productbomInfo" v-if="item.orderStatus==='2'">保单正在核保中，请前往核保服务中查看核保进度。</div>
                <div class="productbomInfo" v-if="item.orderStatus==='3'">保单核保失败，可在核保服务中重新发起核保。</div>
              </div>
            </div>
            <md-scroll-view-more slot="more" :is-finished="listFinished" />
          </md-scroll-view>
          <div class="nodata" v-show="isNull">
            <img class="kongimg" src="@/assets/abd/image/null.png" alt />
            <p class="asdasdqwe">目前还没有内容哦~</p>
          </div>
        </div>
      </div>
    </div>
    <md-popup v-model="isPopupShow" :mask-closable="closable">
      <div class="icdoling">
        <md-icon name="spinner" color="blue" size="lg" style="-webkit-filter:invert(1)"></md-icon>
      </div>
    </md-popup>
  </div>
</template>

<script>
import { Dialog, Toast } from "mand-mobile";
import {
  getMyPageOrder,
  channelCoopCall,
  getCoopOrderlist
} from "@/api/xsqd/index";
import loadMorechange from "@/mixins/loadMorechange";
import { getStorage } from "@/lib/util";
import loadMore from "@/mixins/loadmore";
import Clipboard from 'clipboard'
export default {
  mixins: [loadMore, loadMorechange],
  data() {
    return {
      closable: false,
      isPopupShow: true,
      // isNull: true,
      scrollTop: 0,
      ddstatus: 3,
      pageSize: 6,
      loading: false,
      reportList: [],
      pageNo: 1,
      totalPage: 0,
      listRsFinished: true,
      loadRsflag: false,

      listpageNo: 1,
      listsize: 10,
      listtotal: 0,
      listtotalPage: 0,
      listFinished: true,
      loadflag: false,
      statusType: "",
      isNull: false,

      current: 3,
      isInit: false,
      user: {},
      items: [
        { name: 3, label: "全部订单" },
        { name: 0, label: "待处理" },
        { name: 2, label: "已退保" },
        { name: 1, label: "已完成" }
      ],
      isActive: "R",
      reportListCaixian: []
    };
  },
  // mixins: [loadMorechange],
  created() {
    this.user = getStorage("u_s", {});
    this.ddstatus = Number(this.$route.query.ddstatus);
    this.current = Number(this.$route.query.ddstatus);
    if (sessionStorage.getItem("ddstatus")) {
      this.ddstatus = Number(sessionStorage.getItem("ddstatus"));
      this.current = Number(sessionStorage.getItem("ddstatus"));
    }
    if (sessionStorage.getItem("isActive")) {
      this.isActive = sessionStorage.getItem("isActive");
    }
    this.getData();
    //  new ClipboardJS('.copy').on('success', e => {
    //   Toast.loading("复制成功");
    //   // this.sharebtn = true
    //   e.clearSelection()
    // })
  },
  methods: {
    //删除订单
    deldata(val) {
      Dialog.failed({
        title: "删除订单",
        content: "此操作将删除订单",
        confirmText: "确定",
        onConfirm: () => {
          orderformdelete({ orderno: val }).then(res => {
            this.pageNo = 1;
            this.reportList = [];
            // this.listFinished = false
            this.loadRsflag = false;
            this.$refs.scrollView.finishLoadMore();
            this.getData();
          });
        }
      });
    },
    //标签切换
    onTabChange(item, index, prevIndex) {
      if (item.label == "全部订单") {
        this.ddstatus = 3;
      } else if (item.label == "待处理") {
        this.ddstatus = 0;
      } else if (item.label == "已完成") {
        this.ddstatus = 1;
      } else if (item.label == "已退保") {
        this.ddstatus = 2;
      }
      if (this.isActive === "R") {
        this.pageNo = 1;
        this.reportList = [];
        // this.listFinished = false
        this.loadRsflag = false;
        if (this.reportList.length !== 0) {
          this.$refs.scrollView.finishLoadMore();
        }
        this.getData();
      }
      if (this.isActive === "C") {
        this.listpageNo = 1;
        this.reportListCaixian = [];
        // this.listFinished = false
        this.loadflag = false;
        if (this.reportListCaixian.length !== 0) {
          this.$refs.scrollView.finishLoadMore();
        }
        this.goToCaixian();
      }
    },

    //查看订单
    skip(row) {
      if (this.isActive === "R") {
        sessionStorage.setItem("isActive", this.isActive);
        sessionStorage.setItem("ddstatus", this.current);
        if (row.ddstatus == "1") {
          window.location.href = `${row.orderurl}appno=${row.appno}&isqd=true&comid=NHWX`;
        } else if (row.ddstatus == "0") {
          // window.location.href
        }
      } else {
        sessionStorage.setItem("isActive", this.isActive);
        sessionStorage.setItem("ddstatus", this.current);
        this.$router.push({
          name: "myorderDetail",
          query: { channelOrderCode: row.channelOrderCode }
        });
      }
    },
    //获取订单数据
    getData() {
      if (this.isActive === "R") {
        let data = {
          salecomid: this.user.salecomid,
          saleempno: this.user.empno,
          ddstatus: this.ddstatus
        };
        // this.isPopupShow = true;
        getMyPageOrder(data).then(res => {
          this.reportList =res.data.data.riskcon
            // this.reportList == []
            //   ? res.data.data.riskcon
            //   : this.reportList.concat(res.data.data.riskcon);
          if (this.reportList.length == 0) {
            this.isNull = true;
          } else {
            this.isNull = false;
          }
          this.totalPage = res.data.data.riskcon.length;
          this.isPopupShow = false;
          this.loadRsflag = true;
        });
      }
      if (this.isActive === "C") {
        if (this.ddstatus === 3) {
          this.statusType = 0;
        }
        if (this.ddstatus === 0) {
          this.statusType = 1;
        }
        if (this.ddstatus === 1) {
          this.statusType = 3;
        }
        if (this.ddstatus === 2) {
          this.statusType = 2;
        }
        let data = {
          empno: this.user.empno,
          statusType: Number(this.statusType),
          orderType: "2",
          pageNo: this.listpageNo,
          pageSize: this.listsize,
          prodName: "", //搜索条件字段
          correctNo: "",
          compPlcNo: "",
          paidName: "",
          ipaidName: ""
        };
        this.isPopupShow = true;
        getCoopOrderlist(data).then(res => {
          this.isPopupShow = false;
          this.reportListCaixian =
            this.reportListCaixian == []
              ? res.data.data.records
              : this.reportListCaixian.concat(res.data.data.records);
          if (this.reportListCaixian.length == 0) {
            this.isNull = true;
          } else {
            this.isNull = false;
          }
          this.listtotal = res.data.data.total;
          this.listtotalPage = res.data.data.pages;
          if (this.listtotalPage <= this.listpageNo) {
            this.listFinished = true;
          } else {
            this.listFinished = false;
            this.$nextTick(() => {
              this.$refs.scrollView.finishLoadMore();
            });
          }
          this.loadflag = true;
        });
      }
    },
    selectRisk(val) {
      this.ddstatus = Number(this.$route.query.ddstatus);
      this.current = Number(this.$route.query.ddstatus);
      if (val === "rsx") {
        this.isActive = "R";
        this.getData();
      } else {
        this.isActive = "C";
        this.goToCaixian();
      }
      sessionStorage.setItem("isActive", this.isActive);
      sessionStorage.setItem("ddstatus", this.current);
    },
    //财产险接口调用
    goToCaixian() {
      //  if(this.ddstatus===3){
      //     this.statusType=0
      //   }
      //   if(this.ddstatus===0){
      //     this.statusType=1
      //   }
      //    if(this.ddstatus===1){
      //     this.statusType=3
      //   }
      //    if(this.ddstatus===2){
      //     this.statusType=2
      //   }
      this.listpageNo = 1;
      this.reportListCaixian = [];
      this.listFinished = false;
      this.loadflag = false;
      if (this.reportListCaixian.length !== 0) {
        this.$refs.scrollView.finishLoadMore();
      }
      this.getData();
    },
    channelCoopCall(mg1, mg2, mg3, val) {
      sessionStorage.setItem("isActive", this.isActive);
      sessionStorage.setItem("ddstatus", this.current);
      let params = {
        bizType: 1,
        data: {
          orderNo: mg1,
          invoiceNo: "",
          correctNo: mg2
        },
        channel: mg3,
        operType: val
      };
      if (val === "6") {
        Toast.loading("删除中...");
      } else {
        Toast.loading("跳转中...");
      }
      channelCoopCall(params)
        .then(res => {
          Toast.hide();
          if (res.data.code === 200) {
            if (res.data.data) {
              window.location.href = res.data.data;
            } else {
              if (val === "6") {
                Toast.succeed("删除成功！");
                this.goToCaixian();
              }
            }
          }
        })
        .catch(err => {
          Toast.hide();
        });
    },
    deleteOrder(v1, v2, v3, v4) {
      Dialog.failed({
        title: "删除订单",
        content: "此操作将删除订单",
        confirmText: "确定",
        onConfirm: () => {
          this.channelCoopCall(v1, v2, v3, v4);
        }
      });
    },
    //   // 复制成功
    onCopyUrl (mes) {
      if (!mes) {
        Toast.info('暂无数据')
      } else {
        var clipboard = new Clipboard('.payMony', {
          text: function () {
            return mes
          }
        })
        clipboard.on('success', e => {
          Toast.succeed('支付链接复制成功！')
          e.clearSelection()
        })
      }
    },
  }
};
</script>

<style lang="stylus" scoped>
.mainbody {
  // background :#fff;
  background-color: #F7F6FB;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.orderformtop {
  padding-top: 1rem;
}

/deep/ .md-tab-bar {
  background: #fff;
}

.marginleft {
  margin-left: 10px;
}

/deep/ .md-tab-bar-ink {
  width: 0px !important;
}

/deep/ .md-tab-bar-item {
  font-size: 0.4rem;
  font-weight: bold;
  margin-top: 5px;
  color: #333333;
  min-height: 1.25rem;
}

/deep/ .is-active {
  border-bottom: 5px solid;
  color: #FF7326;
}

.omit {
  margin-left: 30px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.44rem;
  font-weight: bold;
  color: #373737;
}

.list {
  margin: 0.5rem 0.32rem 0.24rem 0.32rem;
  height: 100vh;
}

.product {
  margin-bottom: 25px;
  border-radius: 0.16rem;
}

.producttop {
  background: #fff;
  display: flex;
  height: 100px;
  line-height: 100px;
  border-top-left-radius: 0.16rem;
  border-top-right-radius: 0.16rem;
}

.producttopl {
  width: 80%;
  display: flex;
  align-items: center;
}

.producttopl img {
  border: 1px solid #F1DEE4;
  margin: 0 30px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.producttopr {
  width: 20%;
  text-align: center;
}

.producttopr p {
  margin-right: 30px;
  text-align: right;
  // display: flex;
}

.producttopr span {
  // display: flex;
  // align-items: center;
  font-size: 0.35rem;
  color: #FF7326;
  font-weight: bold;
}

.producttopr img {
  width: 15px;
  height: 15px;
}

// .producttopr .succe {
// color: #00ae28;
// }
.productcen {
  background: #fff;
  padding-bottom: 30px;
  width: 72.5%;
}

.productcon {
  background: #fff;
  display: flex;
  height: 60px;
  line-height: 60px;
}

// .productconl {
// width: 50%;
// }
.orderContent {
  background: #fff;
  display: flex;
}

.lookOrder {
  width: 2.27rem;
  height: 0.67rem;
  line-height: 0.67rem;
  border-radius: 0.33rem;
  border: 1px solid #FF7E3A;
  font-size: 0.32rem;
  font-weight: 500;
  color: #FF7E3A;
  text-align: center;
  margin-top: 18%;
}
.productconl{
  width:100%;
}
.productconl p {
  font-size: 0.34rem;
  color: #535353;
  margin-left: 30px;
}
.insuredName p {
  white-space:nowrap; 
  overflow:hidden; 
  text-overflow:ellipsis;
}

.productconr {
  width: 50%;
}

.productconr p {
  font-size: 37px;
  color: #514E51;
  float: right;
  margin-right: 30px;
}

.productbom {
  background: #fff;
  // display: flex;
  // height: 150px;
  // line-height: 150px;
  border-top: 1px solid #EEEEEE;
  padding: 0 0.29rem 0.29rem 0.29rem;
  border-bottom-left-radius: 0.16rem;
  border-bottom-right-radius: 0.16rem;

  .productbomFrist {
    display: flex;
    align-items: center;
  }

  .productbomInfo {
    font-size: 0.29rem;
    margin-left: 0.03rem;
    font-family: PingFang-SC-Medium;
    font-weight: 500;
    color: #FF7E3A;
    line-height: 0.45rem;
  }
}

.totalMount {
  padding: 0.25rem 0 0.1rem 0;
  display: flex;
  width: 40%;

  .payTime {
    font-weight: 0.29rem;
    color: #FF7E3A;
    font-weight: 500;
  }
}

.productboml {
  // margin-left: 30px;
  flex: 1;
  // text-align: right;
}

.productbomr {
  // width: 25%;
  flex: 1;
  align-items: center;
  text-align: right;
  // padding-right: 0.3rem;
}

.deleteshow {
  // margin-left:42%
}

.producttg {
  width: 40%;
  text-align: center;
}

.productbom /deep/ .md-button.small {
  height: 100%;
  text-align: -webkit-right;
}

.productbomr /deep/ .md-button-inner {
  margin-left: 1px;
  width: 2.27rem;
  height: 0.67rem;
  border: 1px solid #676767;
  border-radius: 0.33rem;
  color: #676767;
}

.productbomr /deep/ .md-button-content {
  font-size: 0.32rem;
}

.payMony {
  width: 2.27rem;
  height: 0.67rem;
  line-height: 0.67rem;
  border-radius: 0.33rem;
  background: #FF7E3A;
  color: #ffffff;
  font-size: 0.32rem;
  text-align: center;
  margin-left: 0.35rem;
}

.showBtn {
  display: flex;
  padding: 0.3rem 0 0.1rem 0;
  width: 60%;
  justify-content: flex-end;
  // padding-bottom: 0.32rem;
}
.gotoPay{
  width:100%
}

.wfont {
  color: #3f3f3f;
  font-family: PingFang-SC-Regular;
  font-weight: 400;
  font-size: 0.32rem;

  .mondyUnit {
    font-size: 0.27rem;
    font-family: PingFang-SC-bold;
    font-weight: bold;
    color: #242424;
  }
}

.hfont {
  color: #242424;
  font-size: 0.48rem;
  font-family: PingFang-SC-bold;
  font-weight: bold;
  // font-weight: 400;
}

/deep/ .icdoling .md-icon {
  width: 1.42rem;
  height: 1.42rem;
  line-height: 1.42rem;
}

.tabBar {
  // position: fixed;
  // top: 0;
  width: 100%;
  background: #ffffff;
}

.selectInsurance {
  text-align: center;
  padding: 0.3rem 0;
  background: #fff;
}

.longRisk, .shortRisk {
  width: 2.4rem;
  height: 0.8rem;
  line-height: 0.7rem;
  border: 1px solid #FF7326;
  display: inline-block;
  font-size: 0.4rem;
  font-family: PingFang-SC-Medium;
  font-weight: 500;
  color: #333;
}

.longRisk {
  border-top-left-radius: 0.13rem;
  border-bottom-left-radius: 0.13rem;
}

.shortRisk {
  border-left: none;
  border-top-right-radius: 0.13rem;
  border-bottom-right-radius: 0.13rem;
}

.isActive {
  background: #FF7326;
  color: #fff;
  font-family: PingFang-SC-Bold;
  font-weight: bold;
}

.nodata {
  padding-top: 30%;
  text-align: center;

  .kongimg {
    width: 4rem;
    height: 4rem;
  }
}
</style>
